<template>
  <div>
    <v-autocomplete
      label="Filter by Customer"
      placeholder="All customers"
      clearable
      class="mx-4"
      v-model="selectedCustomer"
      :items="customers"
      item-value="id"
      item-text="name"
      return-object
      @change="
        productFilter = null;
        filter();
      "
    />
    <v-autocomplete
      v-if="isMobile"
      label="Filter by Product"
      placeholder="All products"
      class="mx-4"
      clearable
      @click.prevent.stop=""
      v-model="productFilter"
      :items="products"
      @change="filter"
    />
    <v-data-table
      class="inventory-details"
      v-if="eventList"
      :headers="headers"
      :items="filteredEventList"
      item-key="name"
    >
      <template v-slot:header.inventoryId="{}">
        <v-autocomplete
          label="Product"
          placeholder="Filter by product"
          hide-details
          dense
          clearable
          @click.prevent.stop=""
          v-model="productFilter"
          :items="products"
          @change="filter"
        />
      </template>
      <template v-slot:item.inventoryId="{ item }">
        <a
          target="_blank"
          :href="`https://prod.${domain}.com/admin/inventory/${item.inventoryId}`"
        >
          {{ item.product }}
        </a>
      </template>
      <template v-slot:item.time="{ item }">
        {{ item.time | formatDateTime }}
      </template>
      <template v-slot:item.quantity="{ item }">
        {{ (item.quantity * -1) | formatNumber }}
      </template>
      <template v-slot:item.salesWeightAdjustment="{ item }">
        {{ item.salesWeightAdjustment | formatNumber }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ item.price | currency }}
      </template>
      <template v-slot:item.saleItemUnitPrice="{ item }">
        {{ item.saleItemUnitPrice | currency }}
      </template>
      <template v-slot:item.value="{ item }">
        <div v-if="item.costAdjustment != 0">
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="red--text">{{ item.value | currency }}</span>
              </div>
            </template>
            <v-list>
              <v-layout column style="height: 60vh">
                <v-flex style="overflow: auto">
                  <v-simple-table class="ma-4">
                    <thead>
                      <tr>
                        <th colspan="2" class="text-center">
                          Cost Adjustments
                        </th>
                      </tr>
                      <tr>
                        <th>Amount</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(adj, index) in getCostAdjustments(
                          item.inventoryId,
                          date
                        )"
                        :key="index"
                      >
                        <td>{{ adj.amount | currency }}</td>
                        <td>
                          <div class="ma-2">{{ adj.notes }}</div>
                          <div class="text-caption text-right ma-2">
                            {{ adj.adjustmentTime | formatDateYear }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex></v-layout
              >
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          {{ (item.value * -1) | currency }}
        </div>
      </template>
      <template v-slot:item.unitCost="{ item }">
        {{ item.unitCost | currency }}
      </template>
      <template v-slot:item.net="{ item }">
        {{ item.net | currency }}
      </template>
      <template v-slot:item.margin="{ item }">
        {{ item.margin.toFixed(2) }}%
      </template>

      <template v-if="summary" :slot="isMobile ? 'body.prepend':'body.append'">
          <tr v-if="isMobile" class="v-data-table__mobile-table-row">
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">Total Quantity</div>
              <div class="v-data-table__mobile-row__cell">{{ summary.quantity | formatNumber }}</div>
            </td>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">Total Weight Adj.</div>
              <div class="v-data-table__mobile-row__cell">{{ summary.weightAdj | formatNumber }}</div>
            </td>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">Total Price</div>
              <div class="v-data-table__mobile-row__cell">{{ summary.price | currency }}</div>
            </td>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">Total Cost</div>
              <div class="v-data-table__mobile-row__cell">{{ summary.value | currency }}</div>
            </td>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">Total Net</div>
              <div class="v-data-table__mobile-row__cell">{{ summary.net | currency }}</div>
            </td>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">Ave Margin</div>
              <div class="v-data-table__mobile-row__cell">{{ (summary.net/summary.price * 100).toFixed(2) }}%</div>
            </td>
          </tr>
          <tr v-else>
            <th colspan="3">
              Total
            </th>
            <th>
              {{ summary.quantity | formatNumber }}
            </th>
            <th>
              {{ summary.weightAdj | formatNumber }}
            </th>
            <th>
              {{ summary.price | currency }}
            </th>
            <th></th>
            <th>
              {{ summary.value | currency }}
            </th>
            <th></th>
            <th>{{ summary.net | currency }}</th>
            <th>{{ (summary.net/summary.price * 100).toFixed(2) }}%</th>
            <th colspan="2"></th>
          </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    eventList: {
      type: Array,
      required: true,
      default: () => [],
    },
    domain: {
      type: String,
      required: false,
      default: "localhost",
    },
    date: {
      type: Date,
    },
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  data: function () {
    return {
      headers: [
        { text: "Inventory ID", value: "inventoryId" },
        { text: "Time", value: "time" },
        { text: "Customer", value: "companyName" },
        { text: "Quantity", value: "quantity" },
        { text: "Weight Adj", value: "salesWeightAdjustment" },
        { text: "Price", value: "price" },
        { text: "Unit price", value: "saleItemUnitPrice" },
        { text: "Cost", value: "value" },
        { text: "Unit Cost", value: "unitCost" },
        { text: "Net", value: "net" },
        { text: "Margin", value: "margin" },
      ],
      summary: null,
      customers: [],
      selectedCustomer: null,
      filteredEventList: [],
      productFilter: null,
      products: [],
    };
  },
  mounted() {
    this.filteredEventList = this.eventList;
    this.summarize();
  },
  methods: {
    getCostAdjustments(inventoryId, balanceDate) {
      if(this.costAdjustmentService){
        return this.costAdjustmentService.getCostAdjustmentDetails(
          inventoryId,
          balanceDate
        );
      }
      else{ 
        console.warn(`cost adjustments service not found when looking up inventory ${inventoryId} on ${balanceDate}`);
        return null;
      }
    },
    filter() {
      this.filteredEventList = this.eventList;

      if (this.selectedCustomer) {
        this.filteredEventList = this.filteredEventList.filter(
          (item) => item.companyId == this.selectedCustomer.id
        );
      }
      if (this.productFilter && this.productFilter.trim() !== "") {
        this.filteredEventList = this.filteredEventList.filter(
          (item) => item.product == this.productFilter
        );
      }

      this.summarize();
    },
    summarize() {
      const products = new Set();

      this.summary = this.filteredEventList.reduce((group, row) => {
        //pull unique Customers
        const companyId = row.companyId;
        if (!this.customers.find((c) => c.id == companyId)) {
          this.customers.push({ id: companyId, name: row.companyName });
        }

        products.add(row.product);

        //assign price using sales unit Price
        const unitPrice = row.saleItemUnitPrice;
        const salesWeightAdjustment = row.salesWeightAdjustment ? row.salesWeightAdjustment:0;
        const priceBaseUnit = row.salePriceBaseUnit;
        const quantity = Math.abs(row.quantity);
        const cost = Math.abs(row.value);

        if (priceBaseUnit === "Weight") {
          row.price = unitPrice * (quantity + salesWeightAdjustment);
        } else {
          row.price = unitPrice * row.unit;
        }

        row.net = row.price - cost;
        row.margin = (row.net / row.price) * 100;

        if (group) {
          group.value += cost;
          group.quantity += quantity;
          group.weightAdj += salesWeightAdjustment;
          group.price += row.price;
          group.net += row.price - cost;
        } else {
          group = {
            value: cost,
            quantity: quantity,
            weightAdj: salesWeightAdjustment,
            price: row.price,
            net: row.price - cost,
          };
        }

        return group;
      }, null);

      this.customers.sort((a, b) => a.name.localeCompare(b.name));
      this.products = [...products];
    },
  },
};
</script>

<style scoped>
.inventory-details thead.v-data-table-header > tr > th > div {
  display: inline-block;
  width: 80%;
}
</style>
